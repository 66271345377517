import React , {useState} from "react";
import data from '../data/data.json';
export const Services = (props) => {

  const [showRecursosHumanos, setShowRecursosHumanos] = useState(true);
  const [showContabilidad, setShowContabilidad] = useState(false);

  const handleRecursosHumanosButtonClick = () => {
    setShowRecursosHumanos(true);
    setShowContabilidad(false);
  };

  const handleContabilidadButtonClick = () => {
    setShowContabilidad(true);
    setShowRecursosHumanos(false);
  };
  return (
    <div id="services" className="text-center">
      <div className="container ">
        <div className="section-title">
          <h2>Nuestros Servicios</h2>
          <p>
            Descubre nuestra amplia gama de servicios. Contáctanos y juntos diseñaremos un plan a tu medida. 
          </p>
          <div style={{ marginTop: "40px" }}>
            <button style={{ marginRight: "50px" }} className="btn btn-custom btn-lg page-scroll " onClick={handleRecursosHumanosButtonClick}>Recursos Humanos </button> 
            
            <button className="btn btn-custom btn-lg page-scroll" style={{ paddingLeft: "61px",paddingRight: "61px"}} onClick={handleContabilidadButtonClick}>Contabilidad</button>
          </div>
        </div>
        
        {showRecursosHumanos && (
           <div className="row">
          {data.rrhh
            ? data.rrhh.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-lg-3">
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>
              {d.text.split('\n').map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
            </p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
        
      )}
      {showContabilidad && (
         <div className="row">
         {data.cont
           ? data.cont.map((d, i) => (
               <div key={`${d.name}-${i}`} className="col-lg-4" >
                 <i className={d.icon}></i>
                 <div className="service-desc">
                   <h3>{d.name}</h3>
                   <p>{d.text}</p>
                   
                 </div>
               </div>
             ))
           : "Cargando"}
       </div>
      )}
      </div>
      
    </div>
  );
};

import React, { useState } from 'react';
import axios from 'axios';

export const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [company, setCompany] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    // Mostrar datos y URL para depuración
    //console.log('Datos de registro:', { name, email, password, company });
    //console.log('URL de solicitud:', '/backend/auth.php?action=register');

    try {
      const response = await axios.post('/backend/auth.php?action=register', 
        { name, email, password, company }, 
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      console.log('Respuesta del servidor:', response.data);

      if (response.data.success) {
        alert('Registration successful!');
        window.location.href = '/login';
      } else {
        alert('Registration failed: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error al registrar:', error);

      if (error.response) {
        console.error('Respuesta del servidor con error:', error.response.data);
        alert('Error en la solicitud: ' + error.response.data.message);
      } else if (error.request) {
        console.error('No se recibió respuesta del servidor:', error.request);
        alert('No se recibió respuesta del servidor.');
      } else {
        console.error('Error en la configuración de la solicitud:', error.message);
        alert('Error en la configuración de la solicitud: ' + error.message);
      }
    }
  };

  return (
    <div className="container" style={{ marginTop: '100px' }}>
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title text-center">Register</h2>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Password:</label>
                  <input
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Confirm Password:</label>
                  <input
                    type="password"
                    className="form-control"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Company:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-primary btn-block">
                  Register
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

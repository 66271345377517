import React from "react";

export const About = (props) => {
  return (
    <div style={{ backgroundImage: "url('img/About3.jpg')", backgroundSize: 'cover', backgroundPosition: 'center center', padding:'100px 0px' }}>
  
      <div id="about" className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
          </div>
          <div className="col-xs-12 col-md-6" style ={{background:'white',padding:'15px 30px'}}>
            <div className="about-text section-title" >
              <h2>Sobre Nosotros</h2>
              <p>
                  {props.data ? 
                    props.data.paragraph.split('\n').map((text, index) => (
                      <React.Fragment key={index}>
                        {text}
                        <br />
                      </React.Fragment>
                    )) 
                    : "loading..."}
                </p>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
// eslint-disable-next-line 
// import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import { Login } from "./components/Login/LoginPage"; // Importar el componente de Login
import { Register } from "./components/Login/Register"; 
import { Intranet } from "./components/Login/Intranet"; // Importar el componente de Intranet
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider } from "./context/AuthContext.js";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <AuthProvider>
      <Router>
        <div>
          <Navigation />
          <div style={{ marginTop: '60px' }}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/intranet" element={
                <PrivateRoute>
                  <Intranet />
                </PrivateRoute>
              } />
              <Route path="/" element={
                <>
                  <Header data={landingPageData.Header} />
                  <Services data={landingPageData.Services} />
                  <About data={landingPageData.About} />
                  <Team data={landingPageData.Team} />
                  <Contact data={landingPageData.Contact} />
                </>
              } />
            </Routes>
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;